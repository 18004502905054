import React from 'react';
import './App.css';
import {Parallax} from "react-parallax";

function App() {

    return (
        <div className="wrapper">
            <section className='header_section'>
                <div className="header">
                    <div className="logo">
                        <a id='logo' href="http://www.caprox.com">
                            <img src={require('./assets/images/Header-logo.png')} alt="Logo"/>
                        </a>
                    </div>
                </div>
            </section>
            <section className='second_section'>
                <img src={require('./assets/images/main-image.png')} alt="Main"/>
            </section>
            <section className='third_section'>
                <a href="mailto:caprox@caprox.com">caprox@caprox.com</a>
            </section>
            <section className="fourth_section">
                <Parallax bgImage={require('./assets/images/lineimage.jpg')} strength={500}>
                    <div className='fourth_section_parallax'>
                        <div className='content'>
                            <div className="title">
                                <span>Capricorn Procurement Consulting</span>
                            </div>
                            <div className="description">
                                <span>The range of defense products and services covers the needs of the armed forces, the army, navy and air force, and expand to the needs of coast and border guard, air defense equipment, special operations teams and police forces.</span>
                                <span>Our activity is focusing mainly in the fields of participation in purchase, consulting in different kinds of contracts in regards to acquiring munitions and military equipment.</span>
                            </div>
                        </div>
                    </div>
                </Parallax>
            </section>
            <section className="fifth_section">
                <div className="title">
                    <span>Capricorn <br/> Military <br/> Consulting</span>
                </div>
                <div className="map">
                    <img src={require('./assets/images/world-map.png')} alt="World map"/>
                </div>
                <div className="description">
                    <span>Trade in military weapons and ammunitions represents one of the main activities of Capricorn, and the products range from smaller military arms and ammunition to larger weapons systems.</span>
                </div>
            </section>
            <section className="sixth_section">
                <div className="content">
                    <div className="title">
                        <span>Foreign trade assist <br/> in military equipment</span>
                    </div>
                    <div className="sub_title">
                        <span>represents one on the main activities of Capricorn.</span>
                    </div>
                    <div className="description">
                        <span>The company is represented by highly qualified staff with a strong understanding of foreign trade. We can assist with transport of goods through close connections to specialized transport companies.</span>
                        <span>The staff have been operating in the international military markets from the nineties in different companies.</span>
                    </div>
                </div>
                <div className="parallax_container">
                    <Parallax bgImage={require('./assets/images/missilecut2.png')} strength={500}>
                        <div className='sixth_section_parallax'>
                        </div>
                    </Parallax>
                </div>
            </section>
            <section className="footer_section">
                <span>Copyright Caprox</span>
            </section>
        </div>
  );
}

export default App;
